/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

// Default: 0.75s
const animationDuration = '0.2s';

// Default 3px
const px3 = '0px';

// Default 2px
const px2 = '0px';

// Default 1px
const px1 = '0px';

// Default 2deg
const deg2 = '1deg';

// ${deg1}: 1deg;
const deg1 = '1deg';

const HvrBuzzOutAnimation = keyframes`
  10% {
    -webkit-transform: translateX(${px3}) rotate(${deg2});
    transform: translateX(${px3}) rotate(${deg2});
  }
  20% {
    -webkit-transform: translateX(-${px3}) rotate(-${deg2});
    transform: translateX(-${px3}) rotate(-${deg2});
  }
  30% {
    -webkit-transform: translateX(${px3}) rotate(${deg2});
    transform: translateX(${px3}) rotate(${deg2});
  }
  40% {
    -webkit-transform: translateX(-${px3}) rotate(-${deg2});
    transform: translateX(-${px3}) rotate(-${deg2});
  }
  50% {
    -webkit-transform: translateX(${px2}) rotate(${deg1});
    transform: translateX(${px2}) rotate(${deg1});
  }
  60% {
    -webkit-transform: translateX(-${px2}) rotate(-${deg1});
    transform: translateX(-${px2}) rotate(-${deg1});
  }
  70% {
    -webkit-transform: translateX(${px2}) rotate(${deg1});
    transform: translateX(${px2}) rotate(${deg1});
  }
  80% {
    -webkit-transform: translateX(-${px2}) rotate(-${deg1});
    transform: translateX(-${px2}) rotate(-${deg1});
  }
  90% {
    -webkit-transform: translateX(${px1}) rotate(0);
    transform: translateX(${px1}) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-${px1}) rotate(0);
    transform: translateX(-${px1}) rotate(0);
  }
`;

export const HoverBuzzOut = styled.div`
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);

  &:hover,
  &:focus,
  &:active {
    -webkit-animation-name: ${HvrBuzzOutAnimation};
    animation-name: ${HvrBuzzOutAnimation};
    -webkit-animation-duration: ${animationDuration};
    animation-duration: ${animationDuration};
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
`;
