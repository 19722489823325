import styled from 'styled-components';

import variables from './variables';
import { PrimaryButton, InvertedButton } from './buttons';
import { Container, Row, Col4 } from './grid';
import { ArticleTitle, ArticleSubtitle } from './articles';
import { HoverBuzzOut } from './animations';

// HERO STYLES

export const HeroImage = styled.img`
  width: 939px;
  height: 663px;
  max-width: none;

  position: absolute;
  top: -167px;
  left: 12px;
  z-index: 20;

  @media only screen and (max-width: 1084px) {
    position: relative;
    left: auto;
    top: auto;
    width: 100%;
    height: auto;
    margin-top: 80px;
  }
`;

export const HeroImageContainer = styled.div`
  position: relative;

  @media only screen and (max-width: 624px) {
    margin-left: 35px;
  }
`;

export const HeroCTAButton = styled(PrimaryButton)`
  font-weight: 400;
`;

export const HeroDescription = styled.p`
  color: ${variables.textColorSubtitle};
  font-size: 24px;
  line-height: 33px;

  @media only screen and (max-width: 624px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const HeroTitle = styled.h2`
  font-size: 60px;
  line-height: 70px;

  span {
    color: ${variables.colors.green};
    display: block;
  }

  @media only screen and (max-width: 624px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

export const HeroSlogan = styled.div`
  padding-bottom: 66px;

  @media only screen and (max-width: 624px) {
    padding: 0 0 8px;
  }
`;

export const HeroSloganContainer = styled.div`
  max-width: 376px;
  width: 100%;

  @media only screen and (max-width: 1084px) {
    text-align: center;
    max-width: none;
  }
`;

export const HeroWrapper = styled.section`
  padding: 112px;

  @media only screen and (max-width: 1084px) {
    ${Row} {
      display: block;
    }
  }

  @media only screen and (max-width: 624px) {
    padding: 52px 0 0;
  }
`;

// SCHEME STYLES

export const SchemeBlockMobile = styled.div`
  display: none;

  @media only screen and (max-width: 460px) {
    display: block;
    text-align: center;
    padding: 33px 0 44px;
  }

  @media only screen and (max-width: 332px) {
    svg {
      width: 100%;
      height: auto;
    }
  }
`;

export const SchemeImage = styled.img`
  width: 100%;
  height: auto;
`;

export const SchemeBlock = styled.div`
  padding: 48px 0 86px;

  @media only screen and (max-width: 624px) {
    padding: 33px 0 44px;
  }

  @media only screen and (max-width: 460px) {
    display: none;
  }
`;

export const SchemeTitle = styled(ArticleTitle)`
  color: ${variables.invertTextColor};
  display: block;

  span {
    color: ${variables.colors.darkGreen};

    @media only screen and (max-width: 1084px) {
      display: block;
    }
  }
`;

export const SchemeWrapper = styled.section`
  background: ${variables.colors.green};
  background: linear-gradient(
    to bottom,
    ${variables.gradientStart} 20%,
    ${variables.gradientEnd} 100%
  );

  margin-top: -12px;

  position: relative;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;

    width: 54px;
    height: 54px;
    margin-left: -27px;

    background: ${variables.colors.black};

    border-radius: 3px;

    transform: rotate(45deg);
  }

  ${Container} {
    padding: 115px 0 0;
    background: ${variables.colors.green};
    background: linear-gradient(
      to bottom,
      ${variables.gradientStart} 20%,
      ${variables.gradientEnd} 100%
    );
    position: relative;

    @media only screen and (max-width: 624px) {
      padding: 75px 20px 0;
    }
  }
`;

// FEATURES STYLES

export const FeaturesImage = styled.img`
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const FeaturesImageBlock = styled.div`
  position: absolute;
  top: -75px;
  left: -177px;

  @media only screen and (max-width: 1140px) {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    text-align: center;
  }
`;

export const FeaturesBlockListItem = styled.li`
  font-size: 24px;
  color: ${variables.colors.darkGray};
  line-height: 34px;
  position: relative;
  margin: 0 0 0 40px;
  list-style-type: none;

  &:before {
    content: '✔️';
    position: absolute;
    top: 2px;
    left: -30px;
    width: 14px;
    height: 14px;
    color: ${variables.colors.green};
    opacity: 0.3;
  }

  & + & {
    margin-top: 10px;
  }

  @media only screen and (max-width: 624px) {
    font-size: 18px;
    line-height: 24px;

    &:before {
      top: 0;
      left: -20px;
    }
  }
`;

export const FeaturesBlockList = styled.ul``;

export const FeaturesBlockTitle = styled.h3`
  color: ${variables.colors.green};
  font-family: ${variables.fontFamilies.openSans};
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  padding: 0 0 17px;

  @media only screen and (max-width: 624px) {
    font-size: 24px;
    line-height: 40px;
    padding: 0 0 14px;
  }
`;

export const FeaturesBlock = styled.div`
  width: 515px;

  @media only screen and (max-width: 624px) {
    width: auto;
  }
`;

export const FeaturesContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${FeaturesBlock} ~ ${FeaturesBlock} {
    padding-top: 116px;

    @media only screen and (max-width: 624px) {
      padding: 0;
    }
  }

  @media only screen and (max-width: 1140px) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    background: ${variables.mobileFeaturesBG};
    padding: 41px 20px 47px;
  }
`;

export const FeaturesSubtitle = styled(ArticleSubtitle)`
  p {
    color: ${variables.colors.darkGray};
  }
`;

export const FeaturesTitle = styled(ArticleTitle)`
  color: ${variables.colors.darkGreen};
`;

export const FeaturesHeader = styled.header`
  padding: 0 0 135px;

  @media only screen and (max-width: 624px) {
    padding: 0 0 40px;
  }
`;

export const FeaturesWrapper = styled.section`
  padding: 100px 0 0;

  @media only screen and (max-width: 768px) {
    ${Container} {
      padding: 0;
    }
  }

  @media only screen and (max-width: 624px) {
    padding: 48px 0 0;
  }
`;

// CALL TO ACTION SECTION

export const CallToActionButton = styled(InvertedButton)`
  padding: 16px 47px 16px 53px;

  span {
    display: inline-block;
    margin-left: 15px;
  }
`;

export const CallToActionImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 582px;
  height: 380px;

  @media only screen and (max-width: 1084px) {
    display: block;
    position: relative;
    height: auto;
    width: 100%;

    svg {
      margin: 0 auto;
      display: block;
    }
  }

  @media only screen and (max-width: 688px) {
    svg {
      width: 100%;
      height: auto;
    }
  }
`;

export const CallToActionDescription = styled.div`
  font-size: 24px;
  line-height: 34px;
  margin: 13px 0 54px;

  p {
    color: ${variables.colors.darkGreen};
  }

  @media only screen and (max-width: 624px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const CallToActionTitle = styled.h2`
  color: ${variables.invertTextColor};
  font-size: 30px;
  line-height: 44px;

  @media only screen and (max-width: 624px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const CallToActionDetails = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 0 28px;
  }
`;

export const CallToActionColumn = styled.div`
  width: 484px;

  @media only screen and (max-width: 624px) {
    width: auto;
  }
`;

export const CallToActionRow = styled(HoverBuzzOut)`
  position: relative;
  min-height: 380px;
  background: ${variables.colors.green};
  padding: 55px 0 0 65px;

  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: -92px;
    right: 197px;

    width: 114px;
    height: 114px;
    border-radius: 5px;

    background: ${variables.colors.white};
    transform: rotate(45deg);
  }

  @media only screen and (max-width: 768px) {
    padding: 35px 0 0;

    &:before {
      top: -116px;
      left: 50%;
      margin-left: -57px;
    }
  }
`;

export const CallToActionWrapper = styled.section`
  margin: 153px 0 90px;

  @media only screen and (max-width: 624px) {
    margin: 37px 0 50px;
  }

  @media only screen and (max-width: 450px) {
    text-align: center;
  }
`;

// ATTRIBUTES SECTION

export const AttributeDescription = styled.p`
  color: ${variables.contentTextColor};
  font-size: 24px;
  line-height: 35px;

  @media only screen and (max-width: 624px) {
    font-size: 17px;
    line-height: 22px;
  }
`;

export const AttributeTitle = styled.h3`
  font-size: 24px;
  line-height: 35px;
  font-family: ${variables.fontFamilies.openSans};
  font-weight: 700;
  padding-bottom: 10px;

  span {
    font-size: 40px;
  }

  @media only screen and (max-width: 624px) {
    font-size: 17px;
    line-height: 22px;

    span {
      font-size: 24px;
      line-height: 22px;
    }
  }
`;

export const AttributeDetails = styled.div``;

export const AttributeIcon = styled.div`
  height: 100px;

  @media only screen and (max-width: 460px) {
    padding: 0 25px 0 18px;

    svg {
      width: 72px;
      height: auto;
    }
  }
`;

export const AttributesBlock = styled.div`
  text-align: center;

  &:hover {
    ${AttributeIcon} svg {
      transition: all 0.2s ease-in-out;
      width: 100px;
      height: 100px;
    }
  }

  @media only screen and (max-width: 624px) {
    margin-top: 28px;
  }

  @media only screen and (max-width: 460px) {
    display: flex;
  }
`;

export const AttributesWrapper = styled.section`
  padding: 0 0 178px;

  @media only screen and (max-width: 624px) {
    padding: 0 0 93px;

    ${Row} {
      display: block;
    }

    ${Col4} {
      width: 100%;
    }
  }

  @media only screen and (max-width: 460px) {
    padding: 0 0 75px;
  }
`;
